(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("sbAuthorizationLib"), require("mobxReact"), require("sbRespBlockLib"), require("sbJsExtends"));
	else if(typeof define === 'function' && define.amd)
		define("sb-responsive-login-block", ["React", "mobx", "sbAuthorizationLib", "mobxReact", "sbRespBlockLib", "sbJsExtends"], factory);
	else if(typeof exports === 'object')
		exports["sb-responsive-login-block"] = factory(require("React"), require("mobx"), require("sbAuthorizationLib"), require("mobxReact"), require("sbRespBlockLib"), require("sbJsExtends"));
	else
		root["sb-responsive-login-block"] = factory(root["React"], root["mobx"], root["sbAuthorizationLib"], root["mobxReact"], root["sbRespBlockLib"], root["sbJsExtends"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__81__) {
return 